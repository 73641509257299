// -- AUTOGENERATED --

// eslint-disable-next-line no-unused-vars
import APIUtils from '@/util/apiutil'

import Axios from 'axios'
import NETVSConfig from '@/../netvs.config'

/* eslint-disable camelcase */
/* eslint-disable quote-props */
/* eslint-disable object-curly-spacing */
/* eslint-disable array-bracket-spacing */
/* eslint-disable object-shorthand */
export default {

  createParamsList () {
    return {'old': [ ], 'new': [ 'name', 'is_dhcp', 'is_host', 'position', 'rad_type', '_groupname', 'description', 'is_wildcard', 'is_nonterminal', 'do_include_release', 'permission_fq_name']}
  },
  create (config, {name_new, is_dhcp_new, is_host_new, position_new, rad_type_new, _groupname_new, description_new, is_wildcard_new, is_nonterminal_new, do_include_release_new = true, permission_fq_name_new}) {
    const params = { 'new': { 'name': name_new, 'is_dhcp': is_dhcp_new, 'is_host': is_host_new, 'position': position_new, 'rad_type': rad_type_new, '_groupname': _groupname_new, 'description': description_new, 'is_wildcard': is_wildcard_new, 'is_nonterminal': is_nonterminal_new, 'do_include_release': do_include_release_new, 'permission_fq_name': permission_fq_name_new}, 'old': { }}
    // TODO: Return ta-object instead
    return Axios.post(`${NETVSConfig.NETDB_API_BASE_URL}/${NETVSConfig.NETDB_API_VERSION}/dnscfg/fqdn_type/create`, params, (config || {}).netdb_axios_config)
  // eslint-disable-next-line comma-dangle
  },
  deleteParamsList () {
    return {'old': [ '_intname'], 'new': [ ]}
  },
  delete (config, {_intname_old}) {
    const params = { 'new': { }, 'old': { '_intname': _intname_old}}
    // TODO: Return ta-object instead
    return Axios.post(`${NETVSConfig.NETDB_API_BASE_URL}/${NETVSConfig.NETDB_API_VERSION}/dnscfg/fqdn_type/delete`, params, (config || {}).netdb_axios_config)
  // eslint-disable-next-line comma-dangle
  },
  list (config, { gpk, is_own, is_dhcp, is_host, gpk_list, name_list, fetch_limit, is_wildcard, fetch_offset, rad_type_list, is_nonterminal, filter_params_dict, sorting_params_list }) {
    const params = APIUtils.denullify_dict({ 'gpk': gpk, 'is_own': is_own, 'is_dhcp': is_dhcp, 'is_host': is_host, 'gpk_list': (gpk_list === null) ? null : JSON.stringify(gpk_list), 'name_list': (name_list === null) ? null : JSON.stringify(name_list), 'fetch_limit': fetch_limit, 'is_wildcard': is_wildcard, 'fetch_offset': fetch_offset, 'rad_type_list': (rad_type_list === null) ? null : JSON.stringify(rad_type_list), 'is_nonterminal': is_nonterminal, 'filter_params_dict': filter_params_dict, 'sorting_params_list': (sorting_params_list === null) ? null : JSON.stringify(sorting_params_list)})
    const cnf = {}
    Object.assign(cnf, (config || {}).netdb_axios_config)
    cnf.params = params
    return Axios.get(`${NETVSConfig.NETDB_API_BASE_URL}/${NETVSConfig.NETDB_API_VERSION}/dnscfg/fqdn_type/list`, cnf)
  // eslint-disable-next-line comma-dangle
  },
  updateParamsList () {
    return {'old': [ '_intname'], 'new': [ 'is_dhcp', 'is_host', 'position', 'rad_type', '_groupname', 'description', 'is_wildcard', 'is_nonterminal', 'version_name_dict', 'permission_fq_name']}
  },
  update (config, {is_dhcp_new, is_host_new, _intname_old, position_new, rad_type_new, _groupname_new, description_new, is_wildcard_new, is_nonterminal_new, version_name_dict_new, permission_fq_name_new}) {
    const params = { 'new': { 'is_dhcp': is_dhcp_new, 'is_host': is_host_new, 'position': position_new, 'rad_type': rad_type_new, '_groupname': _groupname_new, 'description': description_new, 'is_wildcard': is_wildcard_new, 'is_nonterminal': is_nonterminal_new, 'version_name_dict': version_name_dict_new, 'permission_fq_name': permission_fq_name_new}, 'old': { '_intname': _intname_old}}
    // TODO: Return ta-object instead
    return Axios.post(`${NETVSConfig.NETDB_API_BASE_URL}/${NETVSConfig.NETDB_API_VERSION}/dnscfg/fqdn_type/update`, params, (config || {}).netdb_axios_config)
  // eslint-disable-next-line comma-dangle
  },
}
