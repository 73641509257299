<template>
  <Loading :data="fqdns">
    <div class="fqdn-table" v-if="fqdns">
      <FilterInput v-model="filter"></FilterInput>
      <b-pagination v-if="fqdns.length > per_page && filter === ''" :total-rows="fqdns.length"
                    :per-page="per_page" v-model="current_page" align="center"></b-pagination>
      <b-table :show-empty="true" :empty-text="$t('components.fqdn_table.no_fqdns_available')" :per-page="per_page"
               :current-page="current_page" responsive class="shadow" :items="fqdns" :fields="fqdn_list_fields"
               :filter="filter" @filtered="onFiltered">
        <template v-slot:cell(value)="data">
          <router-link class="nowrap" :to="'/dnsvs/fqdns/'+data.value" v-if="data.item.type_is_nonterminal">
            {{ data.value }}
            <b-badge variant="primary"
                     :title="$tc('components.fqdn_table.fqdns_under_this_fqdn', 1, {count: data.item.sub_fqdn_count})">
              {{ data.item.sub_fqdn_count }}
            </b-badge>
            <b-badge variant="secondary" v-if="data.item.type == 'domain' && data.item.sub_fqdn_count > 0"
                     :title="$t('components.fqdn_table.contains_sub_fqdns')">{{ $tc('system.domain', 1) }}
            </b-badge>
          </router-link>
          <template v-else>{{ data.value }}</template>
          <b-badge variant="danger" :to="'/dnsvs/fqdns/' + data.item.value + '/records'" v-if="data.item.has_rr"
                   :title="$t('components.fqdn_table.resource_records_on_fqdn_available')">
            {{ $t('components.fqdn_table.resource_records_available') }}
          </b-badge>
        </template>
        <template v-slot:cell(info)="data">
          <b-dropdown variant="light">
            <template v-slot:button-content>
              <netvs-icon icon="info"></netvs-icon>
            </template>
            <b-dropdown-item :to="'/dnsvs/fqdns/' + data.item.value + '/records'">
              {{ $tc('components.fqdn_table.record_to_fqdn', 2) }}
            </b-dropdown-item>
            <b-dropdown-item :to="'/dnsvs/fqdns/' + data.item.value + '/references'">
              {{ $tc('components.fqdn_table.reference_to_fqdn', 2) }}
            </b-dropdown-item>
          </b-dropdown>
        </template>
        <template v-slot:cell(actions)="data">
          <b-button-group>
            <b-button :id="'button-evlog-fqdn-' +  data.item.value" variant="outline-secondary"
                      @click="showEvLog(data.item)">
              <netvs-icon icon="evlog"></netvs-icon>
            </b-button>
            <b-button :id="'button-edit-fqdn-' +  data.item.value" variant="outline-primary"
                      @click="editItem(data.item)">
              <netvs-icon icon="edit"></netvs-icon>
            </b-button>
            <b-dropdown @click="deleteItem(data.item, false, false)" split variant="outline-danger">
              <template #button-content>
                <netvs-icon icon="delete"></netvs-icon>
              </template>
              <b-dropdown-item @click="deleteItem(data.item, true, false)">
                {{ $t('components.fqdn_table.delete_fqdn_and_parent_empty_fqdns') }}
              </b-dropdown-item>
              <b-dropdown-item v-if="data.item.has_rr" @click="deleteItem(data.item, false, true)">
                {{ $t('components.fqdn_table.delete_fqdn_and_referencing_dns_records') }}
              </b-dropdown-item>
              <b-dropdown-item v-if="data.item.has_rr" @click="deleteItem(data.item, true, true)">
                {{ $t('components.fqdn_table.all_of_the_above') }}
              </b-dropdown-item>
            </b-dropdown>
          </b-button-group>
          <b-tooltip :target="'button-edit-fqdn-' +  data.item.value" triggers="hover"
                     variant="primary" placement="bottom">
            {{ $t('components.fqdn_table.edit_fqdn') }}
          </b-tooltip>
          <b-tooltip :target="'button-evlog-fqdn-' +  data.item.value" triggers="hover"
                     variant="secondary" placement="left">
            {{ $t('system.event_log') }}
          </b-tooltip>
        </template>
        <template v-slot:head(actions)>
          <b-btn-group class="d-flex" role="group">
            <b-button variant="outline-success"
                      :id="'button-create-fqdn'" @click="createItem()">
              <netvs-icon icon="create"></netvs-icon>
            </b-button>
            <b-button variant="outline-primary"
                      :id="'button-export-record'" @click="exportCSV()">
              <netvs-icon icon="export"></netvs-icon>
            </b-button>
          </b-btn-group>
          <b-tooltip :target="'button-create-fqdn'" triggers="hover"
                     variant="success" placement="left">
            {{ $t('components.fqdn_table.create_new_fqdn') }}
          </b-tooltip>
        </template>
        <b-tooltip :target="'button-export-record'" triggers="hover"
                   variant="primary" placement="bottom">
          {{ $t('components.fqdn_table.export_record_csv') }}
        </b-tooltip>
      </b-table>
      <b-pagination v-if="fqdns && fqdns.length > per_page && filter === ''" :total-rows="fqdns.length"
                    :per-page="per_page" v-model="current_page" align="center"></b-pagination>
      <DBEditor modal_id="edit_fqdn" :input_reducer="create_fqdn_reducer"
                :object_title="object_title"
                :old_data="db_editor_old_data"
                :presets="db_editor_presets"
                :non_optionals_order="['value', 'description', 'type']"
                :object_function="db_editor_function" object_fq_name="dns.fqdn"
                :nullable_non_expert="['description']">
      </DBEditor>
      <EVLogViewer v-if="evlog_fqdn" modal_id="evlog_fqdn_table" :title="'FQDN \''+ evlog_fqdn.value + '\''"
                   ref_obj_fq="dns.fqdn" refobj_id_field="value_list"
                   :refobj_id_value="[evlog_fqdn.value]"></EVLogViewer>

    </div>
  </Loading>
</template>

<script>
import DBEditor from './db-editor/APIObjectDBEditor.vue'
import FQDNTypeService from '@/api-services.gen/dnscfg.fqdn_type'
import Loading from './Loading'
import FilterInput from './FilterInput'
import transactionutil from '@/util/transactionutil'
import FQDNService from '@/api-services.gen/dns.fqdn'
import EVLogViewer from '@/components/EVLogViewer.vue'

export default {
  name: 'FQDNTable',
  components: {
    EVLogViewer,
    Loading,
    DBEditor,
    FilterInput
  },
  props: {
    fqdns: null
  },
  computed: {
    fqdn_list_fields() {
      return [
        {
          label: this.$t('system.fqdn'),
          key: 'value',
          sortable: true
        },
        {
          label: this.$t('system.more_information'),
          key: 'info',
          sortable: false
        },
        {
          label: this.$t('system.description'),
          key: 'description',
          sortable: true
        },
        {
          label: this.$tc('system.action', 2),
          key: 'actions',
          sortable: false
        }
      ]
    }
  },
  data() {
    return {
      per_page: 75,
      filter: '',
      filtered_items: null,
      current_page: 1,
      object_title: null,
      db_editor_function: 'create',
      db_editor_presets: {fqdn: this.fqdn},
      db_editor_old_data: {},
      create_fqdn_reducer: undefined,
      evlog_fqdn: null
    }
  },
  async created() {
    const response = await FQDNTypeService.list(this.$store.state, {sorting_params_list: ['position']})
    const types = response.data[0]
    const selections = {}
    for (const t of types) {
      let disp_name = t.description
      disp_name += ' ['
      disp_name += t.is_nonterminal ? 'nicht-terminal' : 'terminal'
      disp_name += ']'
      selections[t.name] = {display_name: disp_name}
    }
    this.create_fqdn_reducer = {
      type: selections
    }
  },
  methods: {
    deleteItem: function (item, do_del_pqdn, force_del_ref_records) {
      const itc = {}
      Object.assign(itc, item)
      itc.do_del_pqdn = do_del_pqdn
      itc.force_del_ref_records = force_del_ref_records
      const ta = transactionutil.generateDeleteElement('dns.fqdn', FQDNService.deleteParamsList(), itc, this.$t('system.fqdn') + ' ' + item.value +
          (do_del_pqdn ? this.$t('components.fqdn_table.delete_parent_fqdn') : '') +
          (force_del_ref_records ? this.$t('components.fqdn_table.fqdn_delete_dns_records') : '')
      )
      this.$store.commit('addTransactionElement', ta)
      this.$emit('commited', ta)
      if (!this.isMobile() && !this.$store.state.show_sidebar_right) {
        this.$store.commit('showSidebarRight', true)
      }
    },
    showEvLog: function (fqdn) {
      this.evlog_fqdn = fqdn
      this.$nextTick(() => {
        this.$bvModal.show('evlog_fqdn_table')
      })
    },
    editItem: function (item) {
      this.db_editor_function = 'update'
      this.db_editor_old_data = item
      this.db_editor_presets = item
      this.object_title = this.$tc('components.fqdn_table.record_to_fqdn', 1) + ' ' + item.value
      this.$root.$emit('bv::show::modal', 'edit_fqdn')
    },
    createItem: function () {
      this.db_editor_function = 'create'
      this.db_editor_old_data = {}
      this.db_editor_presets = {}
      this.$root.$emit('bv::show::modal', 'edit_fqdn')
    },
    onFiltered(filteredItems) {
      this.filtered_items = filteredItems
    },
    exportCSV: function () {
      if (this.filtered_items == null) {
        // If the method is called and there is no filtered list is available, default to the complete list
        this.filtered_items = this.fqdns
      }

      let content = '"FQDN","FQDN_Description","Type"\n'
      this.filtered_items.forEach((item) => {
        content += `"${item.value ? item.value : ''}",`
        content += `"${item.description ? item.description : ''}",`
        content += `"${item.type ? item.type : ''}",`
        content += '\n'
      })

      const blob = new Blob([content], {type: 'text/csv;charset=utf-8;'})

      const link = document.createElement('a')
      link.download = 'dns-export-' + (this.$route.params.fqdn ? this.$route.params.fqdn : 'all') + '.csv'
      link.href = URL.createObjectURL(blob)
      link.click()
      URL.revokeObjectURL(link.href)
    }
  }
}
</script>

<style scoped>

</style>
